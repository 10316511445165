$c-primary: #353535;
$c-secandary: #353535;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 15px;
  bottom: 0;
  left: 0;

  .buttons {
    margin-left: -7px;
    margin-right: -7px;
    display: flex;
    justify-content: space-between;

    button {
      width: 50%;
      margin: 0 7px;
      max-width: 250px;
    }
  }

  .btnRight {
    text-align: right;
  }

  .buttonsStacked {
    flex-flow: column wrap;
    align-items: center;
    button {
      &:first-child {
        margin-bottom: 2px;
      }
    }
  }
}
