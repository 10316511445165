$c-primary: #353535;
$c-secandary: #353535;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.warpper {
  min-height: 58px;
  display: block;
  z-index: 33;
  background: var(--page-background-color);
  //position: absolute;
  //top: 0;
  //left: 0;
  height: 82.7px;
  //right: 0;
  overflow: hidden;

  .img-container {
    float: left;
    img {
      height: 30px;
      padding: 24px 20px 24px 0;
    }
  }

  .close-container {
    float: right;
    position: relative;
    top: 20px;
    cursor: pointer;
  }

  .langButton-white {
    @extend .langButton;
    background-image: url(_img('icons/svg/language_white.svg'));
  }

  .langButton-black {
    @extend .langButton;
    background-image: url(_img('icons/svg/language_black.svg'));
  }

  .langButton {
    position: absolute;
    border: 0;
    width: 57px;
    height: 57px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 20px 20px;
    top: 0;
    right: 0;
    background-position-y: center;
    background-position-x: center;
    margin-left: -15px;
  }
}

.isDark {
  background: #000 !important;
}

@media (max-width: 760px) {
  .warpper {
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
